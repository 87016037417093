<template>
  <a-skeleton active :loading="ifSub">
    <a-form layout="vertical">
      <a-form-item label="运营单位:" class="margin-button">
        {{initFormItem.company}}
      </a-form-item>
      <a-form-item label="填报日期:" class="margin-button">
        {{moment(initFormItem.reportDate).format('YYYY-MM-DD')}}
      </a-form-item>
<!--      <a-form-item label="所属部门" class="margin-button">-->
<!--        {{initFormItem.departmentId}}-->
<!--      </a-form-item>-->
      <a-form-item label="当日安全生产情况:" class="margin-button">
        {{initFormItem.situation}}
      </a-form-item>
      <a-form-item label="今日安全生产及安全隐患存在主要问题:" class="margin-button">
        {{initFormItem.problem}}
      </a-form-item>
      <a-form-item label="安全生产问题及隐患整改情况:" class="margin-button">
        {{initFormItem.reform}}
      </a-form-item>
    </a-form>
  </a-skeleton>
</template>
<script>
  import SERVICE_URLS from '@/api/service.url'
  import { formVO } from './common'
  import moment from 'moment'

  export default {
    name: 'DepotDetail',
    components: {},
    data () {
      return {
        ifSub: false,
        initFormItem: formVO(),
        labelCol: { span: 4 },
        wrapperCol: { span: 19 },
        tableData: []
      }
    },
    methods: {
      moment,
      loadData (id) {
        this.$http(this, {
          url: SERVICE_URLS.reportForms.safetyProductionApi.view,
          params: { id },
          noTips: true,
          success: (data) => {
            Object.assign(this.initFormItem, data.body)
          }
        })
      }
    }
  }
</script>

<style scoped>
  .text-title {
    font-size: 17px;
    font-weight: 700;
    font-family: 'Lantinghei SC', 'Open Sans', Arial, 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', 'STHeiti',
    'WenQuanYi Micro Hei', SimSun, sans-serif;
  }
</style>
