import entityCRUD from '@/views/common/mixins/entityCRUD'

export default {
  mixins: [ entityCRUD ],
  methods: {
    handleAddOkWithContinue () {
      this.addLoading = true
      if (this.$refs['addRef']) {
        if (typeof this.$refs['addRef'].submitAddWithContinue !== 'undefined'
          && this.$refs['addRef'].submitAddWithContinue instanceof Function) {
          this.$refs['addRef'].submitAddWithContinue()
        } else {
          console.error('Form子组件内部未定义submitAddWithContinue方法')
        }
      } else {
        console.error('Form子组件的addRef未指定')
      }
    },
    handleAddOkWithCopy () {
      this.addLoading = true
      if (this.$refs['addRef']) {
        if (typeof this.$refs['addRef'].submitAddWithCopy !== 'undefined'
          && this.$refs['addRef'].submitAddWithCopy instanceof Function) {
          this.$refs['addRef'].submitAddWithCopy()
        } else {
          console.error('Form子组件内部未定义submitAddWithCopy方法')
        }
      } else {
        console.error('Form子组件的addRef未指定')
      }
    },
    addSuccess (addType) {
      if (addType === 'copy' || addType === 'continue') {
        // 表示不关闭Modal
        this.addLoading = false
      } else {
        // 直接关闭Modal
        this.addLoading = false
        this.addModal = false
      }
      this.addLoading = false
      this.search()
    }
  }
}