export default {
  data() {
    return {
      showFormItem: true,
      createUrl: '',
      viewUrl: '',
      updateUrl: '',
      entityBaseUrl: null,
      entityId: null,
      form: this.$form.createForm(this),
      labelCol: { span: 4 },
      wrapperCol: { span: 19 },
      ifSub: false,
      formItem: {},
    }
  },
  created() {
    if (!this.entityBaseUrl) {
      console.error("请定义'entityBaseUrl'")
    } else {
      this.createUrl = this.entityBaseUrl.create
      this.viewUrl = this.entityBaseUrl.view
      this.updateUrl = this.entityBaseUrl.update
    }
    if (!this.initFormItem) {
      console.error("请定义'initFormItem'")
    }
    Object.assign(this.formItem, this.initFormItem)
  },
  methods: {
    loadData(id) {
      this.$http(this, {
        url: this.viewUrl,
        params: { id },
        noTips: true,
        success: (data) => {
          this.entityId = id
          if (typeof this.setFields !== 'undefined' && this.setFields instanceof Function) {
            this.setFields(data.body)
          } else {
            console.error('Form子组件内部未定义setFields方法')
          }
        },
      })
    },
    submitAdd() {
      this.checkFormAndSendAddHttp((data) => {
        Object.assign(this.formItem, this.initFormItem)
        this.$emit('addSuccess', data)
        this.form.resetFields()
        if (this.formItem.images || this.formItem.files) {
          this.formItem.images = []
          this.formItem.files = []
        }
        this.addSuccessCallback(data)
      })
    },
    submitAddWithContinue() {
      this.checkFormAndSendAddHttp((data) => {
        Object.assign(this.formItem, this.initFormItem)
        this.$emit('addSuccess', 'continue')
        this.form.resetFields()
        if (this.formItem.images || this.formItem.files) {
          this.formItem.images = []
          this.formItem.files = []
        }
        this.addSuccessCallback(data)
        this.addSuccessCustomCallback()
      })
    },
    submitAddWithCopy() {
      this.checkFormAndSendAddHttp((data) => {
        Object.assign(this.formItem, this.initFormItem)
        this.$emit('addSuccess', 'copy')
        this.addCopySuccessCallback(data)
      })
    },
    checkFormAndSendAddHttp(onAddSuccess) {
      this.form.validateFields((err, values) => {
        if (!err) {
          // console.log(1, values)
          if (typeof this.setVOFields !== 'undefined' && this.setVOFields instanceof Function) {
            // console.log(2, this.formItem)
            this.setVOFields(values)
          } else {
            console.error('Form子组件内部未定义setVOFields方法')
          }
          if (this.showFormItem) {
            console.log('确保formItem内容完整后,覆写showFormItem变量为false,之后发送请求')
            console.log('formItem内容如下:')
            console.log(this.formItem)
            return
          }
          if (!this.createUrl) {
            console.error("请在api中定义'create'")
            return
          }
          this.$http(this, {
            url: this.createUrl,
            noTips: true,
            data: this.formItem,
            success: (data) => {
              onAddSuccess(data)
            },
            error: (e) => {
              this.$emit('addError', 'httpError')
              this.addErrorCallback(e)
            },
          })
        } else {
          this.$emit('addError', 'formError')
        }
      })
    },
    // 保存成功关闭或者清空表单
    addSuccessCallback(data) {},
    // 复制保存成功，用于表单复制后其他操作
    addCopySuccessCallback(data) {},
    // 保存成功自定义表单内自定义函数
    addSuccessCustomCallback() {},
    addErrorCallback(e) {
      console.log('网络请求错误:', e)
    },
    submitEdit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          if (typeof this.setVOFields !== 'undefined' && this.setVOFields instanceof Function) {
            this.setVOFields(values)
          } else {
            console.error('Form子组件内部未定义setVOFields方法')
          }
          if (this.showFormItem) {
            console.log('确保formItem内容完整后,覆写showFormItem变量为false,之后发送请求')
            console.log('修改实体的ID为:', this.entityId)
            console.log('formItem内容如下:')
            console.log(this.formItem)
            return
          }
          // 检查ID
          this.$http(this, {
            url: this.updateUrl,
            data: this.formItem,
            params: {
              id: this.entityId,
            },
            success: (data) => {
              this.editSuccessCallback(data)
              this.$emit('editSuccess', data.body)
            },
            error: (e) => {
              this.$emit('editError', 'httpError')
              this.editErrorCallback(e)
            },
          })
        } else {
          this.$emit('editError', 'formError')
        }
      })
    },
    editSuccessCallback(data) {
      if (this.formItem.images || this.formItem.files) {
        this.formItem.imageIds = []
        this.formItem.images = []
        this.formItem.fileIds = []
        this.formItem.files = []
      }
    },
    editErrorCallback(e) {
      console.log('网络请求错误:', e)
    },
  },
}
