<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">安全生产日报表</h1>
    </div>
    <div slot="extra">
      <a-button
        style="margin-left: 16px;"
        icon="plus"
        @click="handleAdd"
        type="primary"
      >新增
      </a-button>
      <a-button
        style="margin-left: 16px;"
        icon="export"
        @click="exportWord"
        type="primary"
      >导出
      </a-button>
    </div>
    <a-card :bordered="false" style="margin-top: 20px;">
      <div class="fs-search-box" style="margin-bottom: 20px;">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="运营单位">
              <a-input placeholder="请输入" v-model="searchParams.company" allowClear></a-input>
            </a-form-item>
            <a-form-item label="所属部门">
              <a-tree-select
                style="width: 210px;"
                :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
                :treeData="deptList"
                allowClear
                placeholder="请选择部门"
                v-model="searchParams.departmentId"
              ></a-tree-select>
            </a-form-item>
            <a-form-item label="填报日期">
              <a-range-picker
                v-model="dateValue"
                allowClear
                :placeholder="['开始时间', '结束时间']"
                format="YYYY-MM-DD"
                @change="handleModalPickerOk"
              ></a-range-picker>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <a-table
        size="middle"
        :columns="columns"
        :dataSource="tableData"
        :loading="tableLoading"
        :pagination="pagination"
        @change="tableChange"
        :customRow="handleView"
        :rowKey="(record) => record.id"
        bordered
      >
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="reportDate" slot-scope="text, record">
          {{ moment(record.reportDate).format('YYYY-MM-DD') }}
        </template>
        <template slot="action" slot-scope="text, record">
          <a @click="handleEdit(record.id)" @click.stop>编辑</a>
          <a-divider type="vertical"/>
          <a @click="handleDelete(record.id)" @click.stop>删除</a>
        </template>
      </a-table>
    </a-card>
    <a-modal title="新增安全生产日报表" v-model="addModal" width="560px">
      <Form ref="addRef" @addSuccess="addSuccess" @addError="addError"></Form>
      <template slot="footer">
        <a-button :loading="addLoading" @click="handleAddOkWithContinue" type="primary" style="margin-right: 5px;"
        >保存新增
        </a-button>
        <a-button :loading="addLoading" @click="handleAddOk" type="primary" style="margin-right: 5px;"
        >保存关闭
        </a-button
        >
        <a-button @click="closeAddModal">取消</a-button>
      </template>
    </a-modal>
    <a-modal
      title="修改安全生产日报表"
      :visible="editModal"
      @ok="handleEditOk"
      @cancel="closeEditModal"
      :confirmLoading="editLoading"
    >
      <Form ref="editRef" @editSuccess="editSuccess" @editError="editError"></Form>
    </a-modal>
    <a-drawer
      title="安全生产日报表详情"
      placement="right"
      :closable="true"
      @close="detailClose"
      :width="500"
      :visible="detailDrawer"
      :mask="false"
    >
      <Detail ref="viewRef"></Detail>
    </a-drawer>
  </page-layout>
</template>

<script>
  import entityCRUDWithCopy from '../../common/mixins/entityCRUDWithCopy'
  import PageLayout from '@/components/page/PageLayout'
  import SERVICE_URLS from '@/api/service.url'
  import Form from './Form'
  import { column } from './common'
  import moment from 'moment'
  import Detail from './Detail'

  export default {
    name: 'myInspection',
    mixins: [entityCRUDWithCopy],
    components: { PageLayout, Form, Detail },
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.reportForms.safetyProductionApi,
        deptList: [],
        userList: [],
        searchParams: {
          page: '0',
          size: '10',
          direction: '',
          order: '',
          company: '',
          departmentId: undefined,
          startDate: '',
          endDate: ''
        },
        dateValue: [],
        initColumns: column(),
        infoModal: false,
        spinning: true,
        userStr: undefined
      }
    },
    created () {
      this.getOrgTree()
    },
    methods: {
      moment,
      getOrgTree () {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.deptList = data.body
          }
        })
      },
      handleModalPickerOk (value) {
        this.searchParams.startDate = value[0].format('YYYY-MM-DD')
        this.searchParams.endDate = value[1].format('YYYY-MM-DD')
        this.search()
      },
      handleView (record) {
        return {
          on: {
            click: () => {
              this.detailDrawer = true
              this.$nextTick(() => {
                this.$refs.viewRef.loadData(record.id)
              })
            }
          }
        }
      },
      resetForm () {
        this.searchParams = {
          page: '0',
          size: '10',
          direction: '',
          order: '',
          company: '',
          departmentId: undefined,
          startDate: '',
          endDate: ''
        }
        this.dateValue = []
        this.search()
      },
      exportWord () {
        const fileName = '安全生产日报表'
        this.handExportWord(fileName)
      }
    }
  }
</script>
