<template>
  <a-spin :spinning="ifSub">
    <a-form :form="form" layout="vertical">
      <a-form-item label="填报日期:" class="margin-button">
        <a-date-picker style="width: 300px"
                       format="YYYY-MM-DD"
                       v-decorator="['reportDate',{rules: [{ required: true, message: '请选择' }]}]"
                       placeholder="请选择"></a-date-picker>
      </a-form-item>
      <a-form-item label="所属部门:" class="margin-button">
        <a-tree-select
          :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
          :treeData="deptList"
          allowClear
          placeholder="请选择部门"
          v-decorator="['departmentId',{rules: [{ required: true, message: '请选择' }]}]"
        ></a-tree-select>
      </a-form-item>
      <a-form-item label="运营单位:" style="width: 300px" class="margin-button">
        <a-input
          v-decorator="['company', { rules: [{ required: true, message: '运营单位不能为空' }] }]"
          placeholder="请输入运营单位"
        ></a-input>
      </a-form-item>
      <a-form-item label="当日安全生产情况:" class="margin-button">
        <a-textarea
          :rows="4"
          v-decorator="['situation']"
          placeholder="请输入当日安全生产情况"
        ></a-textarea>
      </a-form-item>
      <a-form-item label="今日安全生产及安全隐患存在主要问题:" class="margin-button">
        <a-textarea
          :rows="4"
          v-decorator="['problem']"
          placeholder="请输入今日安全生产及安全隐患存在主要问题"
        ></a-textarea>
      </a-form-item>
      <a-form-item label="安全生产问题及隐患整改情况:" class="margin-button">
        <a-textarea
          :rows="4"
          v-decorator="['reform']"
          placeholder="请输入安全生产问题及隐患整改情况"
        ></a-textarea>
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>

  import entityCURDCallback from '../../common/mixins/entityCURDCallback'
  import SERVICE_URLS from '@/api/service.url'
  import { formVO } from './common'

  export default {
    name: 'DepotForm',
    mixins: [entityCURDCallback],
    components: {},
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.reportForms.safetyProductionApi,
        initFormItem: formVO(),
        showFormItem: false,
        deptList: []
      }
    },
    computed: {},
    mounted () {
      this.getOrgTree()
    },
    methods: {
      getOrgTree () {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.deptList = data.body
          }
        })
      },
      setFields (values) {
        Object.assign(this.formItem, values)
        this.form.setFieldsValue({
          company: this.formItem.company,
          departmentId: this.formItem.departmentId,
          problem: this.formItem.problem,
          reform: this.formItem.reform,
          reportDate: this.formItem.reportDate,
          situation: this.formItem.situation
        })
      },
      setVOFields (values) {
        Object.assign(this.formItem, values)
      }
    }
  }
</script>

<style scoped>
  .margin-button {
    margin-bottom: 10px;
  }
</style>
