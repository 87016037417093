export const formVO = function () {
  return {
    depotInfo: {
      company: '',
      departmentId: null,
      problem: '',
      reform: '',
      reportDate: null,
      situation: ''
    }
  }
}
export const column = function () {
  return [
    {
      title: '序号',
      width: 50,
      scopedSlots: { customRender: '_index' },
      align: 'center'
    },
    {
      title: '填报日期',
      width: 100,
      dataIndex: 'reportDate',
      align: 'center',
      scopedSlots: { customRender: 'reportDate' }
    },
    {
      title: '运营单位',
      width: 150,
      dataIndex: 'company',
      align: 'center',
      scopedSlots: { customRender: 'company' }
    },
    {
      title: '当日安全生产情况',
      dataIndex: 'situation',
      align: 'left'
    },
    {
      title: '今日安全生产及安全隐患存在主要问题',
      align: 'left',
      dataIndex: 'problem'
    },
    {
      title: '安全生产问题及隐患整改情况',
      dataIndex: 'reform',
      align: 'left'
    },
    {
      title: '操作',
      width: 150,
      align: 'center',
      scopedSlots: { customRender: 'action' }
    }
  ]
}